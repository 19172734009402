<template>
  <el-main>
    <page-title show-back-btn />
    <div class="partition-area">
      <div v-loading="loading.detail" class="partition-area">
        <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
          <ics-page-inner title="额度申请进度">
            <el-col :span="24">
              <el-steps :active="activeIndex" finish-status="success">
                <el-step title="步骤 1" description="发起申请" />
                <el-step title="步骤 2" description="资方审批" />
                <el-step title="步骤 3" description="完成" />
              </el-steps>
            </el-col>
          </ics-page-inner>
          <ics-page-inner title="申请信息">
            <el-col :span="12">
              <el-form-item label="申请编号">
                <p>{{ utils.isEffectiveCommon(quotaDetail.quotaCode) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="financeType" label="产品类型">
                <p>{{ utils.statusFormat(quotaDetail.financeType, 'productType') }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请企业名称">
                <p>{{ utils.isEffectiveCommon(quotaDetail.coreName) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码">
                <p>{{ utils.isEffectiveCommon(quotaDetail.coreSocialNo) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资金方名称">
                <p>{{ utils.isEffectiveCommon(quotaDetail.capName) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码">
                <p>{{ utils.isEffectiveCommon(quotaDetail.capSocialNo) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请额度(元)">
                <p>{{ utils.moneyFormat(quotaDetail.applyAmount, 2) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附件">
                <p v-for="(item, index) in quotaDetail.files" :key="index">
                  <a href="javascript:" class="text-btn" @click="utils.downloadP('other', item.url)">{{ item.name }}</a>
                </p>
              </el-form-item>
            </el-col>
          </ics-page-inner>
          <ics-page-inner title="审批结果">
            <el-col :span="12">
              <el-form-item prop="applyStatus" label="审批结果" :rules="{ required: true, message: '请选择审批结果', trigger: 'change' }">
                <el-radio-group v-model="appForm.applyStatus" @change="changeApplyStatus">
                  <el-radio label="2">
                    通过
                  </el-radio>
                  <!--                  <el-radio label="3">
                    驳回
                  </el-radio>-->
                  <el-radio label="4">
                    关闭
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col v-if="appForm.applyStatus === '2'" :span="12">
              <el-form-item label="批复额度(元)" prop="passAmount">
                <el-input v-model="appForm.passAmount" />
              </el-form-item>
            </el-col>
            <el-col v-if="appForm.applyStatus === '2'" :span="12">
              <el-form-item label="截至日期" prop="deadline">
                <el-date-picker
                  v-model="appForm.deadline"
                  type="date"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 100%"
                  placeholder="选择日期"
                  :picker-options="pickerOptions"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="审批意见" prop="opinion" :rules="{ required: true, message: '请输入审批意见', trigger: 'blur' }">
                <el-input v-model="appForm.opinion" placeholder="请输入审批意见(限300字)" :maxlength="300" type="textarea" />
              </el-form-item>
            </el-col>
            <ics-button-inner :loading="loading.submit" submit-title="提交" @submit="submitForms" />
          </ics-page-inner>
        </el-form>
      </div>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: {},
  mixins: [routeEnterMixin],
  data () {
    const validateAmount = (rule, value, callback) => {
      if (Number(value) < 0) {
        callback(new Error('批复额度不能小于0'))
      } else if (Number(value) > Number(this.quotaDetail.applyAmount)) {
        callback(new Error('批复额度不能大于申请额度'))
      } else {
        callback()
      }
    }
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/p/access/info/upload?token=' + this.$store.state.token,
      id: this.$route.query.id,
      quotaDetail: {},
      appForm: {
        id: '',
        passAmount: '',
        applyStatus: undefined,
        deadline: '',
        opinion: ''
      },
      activeIndex: 1,
      capList: [],
      coreList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      rules: {
        passAmount: [
          { required: false, message: '请输入批复额度', trigger: 'blur' },
          { validator: validateAmount, trigger: 'blur' }
        ],
        deadline: [
          { required: false, message: '请选择截至日期', trigger: 'change' }
        ],
      }
    }
  },
  created () {
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.quota.core.quotaDetail(this.id).then(result => {
        this.quotaDetail = result.data.data || {}
        this.appForm.id = this.quotaDetail.id
        switch (this.appForm.applyStatus) {
          case 0:
            this.activeIndex = 0
            break
          case 1:
            this.activeIndex = 1
            break
          case 2:
            this.activeIndex = 3
            break
          case 3:
            this.activeIndex = 1
            break
          case 4:
            this.activeIndex = 3
            break
          default:
            this.activeIndex = 0
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    changeApplyStatus (val) {
      switch (val) {
        case '2':
          this.appForm.passAmount = this.quotaDetail.applyAmount
          this.rules.passAmount[0].required = true
          this.rules.deadline[0].required = true
          break
        case '3':
          this.appForm.passAmount = ''
          this.appForm.deadline = ''
          this.rules.passAmount[0].required = false
          this.rules.deadline[0].required = false
          break
        case '4':
          this.appForm.passAmount = ''
          this.appForm.deadline = ''
          this.rules.passAmount[0].required = false
          this.rules.deadline[0].required = false
          break
        default:
      }
      if (val === '3') {
        this.appForm.passAmount = ''
      } else if (val === '4') {
        this.appForm.passAmount = ''
      }
    },
    submitForms () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          const data = this._.cloneDeep(this.appForm)
          this.loading.submit = true
          this.api.quota.core.auditQuota(data.applyStatus, data.passAmount, data.deadline, data.id, data.opinion).then(result => {
            if (result.data.success === true) {
              this.loading.submit = false
              this.$message.success('操作成功')
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
            }
          }).catch(e => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
