var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.detail,
                expression: "loading.detail"
              }
            ],
            staticClass: "partition-area"
          },
          [
            _c(
              "el-form",
              {
                ref: "appForm",
                attrs: {
                  model: _vm.appForm,
                  rules: _vm.rules,
                  "label-width": "180px",
                  "label-suffix": _vm.constants.labelSuffix
                }
              },
              [
                _c(
                  "ics-page-inner",
                  { attrs: { title: "额度申请进度" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-steps",
                          {
                            attrs: {
                              active: _vm.activeIndex,
                              "finish-status": "success"
                            }
                          },
                          [
                            _c("el-step", {
                              attrs: {
                                title: "步骤 1",
                                description: "发起申请"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "步骤 2",
                                description: "资方审批"
                              }
                            }),
                            _c("el-step", {
                              attrs: { title: "步骤 3", description: "完成" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ics-page-inner",
                  { attrs: { title: "申请信息" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "申请编号" } }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.quotaDetail.quotaCode
                                )
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "financeType", label: "产品类型" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.statusFormat(
                                    _vm.quotaDetail.financeType,
                                    "productType"
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "申请企业名称" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.quotaDetail.coreName
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "统一社会信用代码" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.quotaDetail.coreSocialNo
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "资金方名称" } }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.utils.isEffectiveCommon(
                                  _vm.quotaDetail.capName
                                )
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "统一社会信用代码" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.quotaDetail.capSocialNo
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "申请额度(元)" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.moneyFormat(
                                    _vm.quotaDetail.applyAmount,
                                    2
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "附件" } },
                          _vm._l(_vm.quotaDetail.files, function(item, index) {
                            return _c("p", { key: index }, [
                              _c(
                                "a",
                                {
                                  staticClass: "text-btn",
                                  attrs: { href: "javascript:" },
                                  on: {
                                    click: function($event) {
                                      return _vm.utils.downloadP(
                                        "other",
                                        item.url
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ])
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ics-page-inner",
                  { attrs: { title: "审批结果" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "applyStatus",
                              label: "审批结果",
                              rules: {
                                required: true,
                                message: "请选择审批结果",
                                trigger: "change"
                              }
                            }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { change: _vm.changeApplyStatus },
                                model: {
                                  value: _vm.appForm.applyStatus,
                                  callback: function($$v) {
                                    _vm.$set(_vm.appForm, "applyStatus", $$v)
                                  },
                                  expression: "appForm.applyStatus"
                                }
                              },
                              [
                                _c("el-radio", { attrs: { label: "2" } }, [
                                  _vm._v(" 通过 ")
                                ]),
                                _c("el-radio", { attrs: { label: "4" } }, [
                                  _vm._v(" 关闭 ")
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.appForm.applyStatus === "2"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "批复额度(元)",
                                  prop: "passAmount"
                                }
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.appForm.passAmount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appForm, "passAmount", $$v)
                                    },
                                    expression: "appForm.passAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.appForm.applyStatus === "2"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "截至日期", prop: "deadline" }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "选择日期",
                                    "picker-options": _vm.pickerOptions
                                  },
                                  model: {
                                    value: _vm.appForm.deadline,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appForm, "deadline", $$v)
                                    },
                                    expression: "appForm.deadline"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "审批意见",
                              prop: "opinion",
                              rules: {
                                required: true,
                                message: "请输入审批意见",
                                trigger: "blur"
                              }
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入审批意见(限300字)",
                                maxlength: 300,
                                type: "textarea"
                              },
                              model: {
                                value: _vm.appForm.opinion,
                                callback: function($$v) {
                                  _vm.$set(_vm.appForm, "opinion", $$v)
                                },
                                expression: "appForm.opinion"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("ics-button-inner", {
                      attrs: {
                        loading: _vm.loading.submit,
                        "submit-title": "提交"
                      },
                      on: { submit: _vm.submitForms }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }